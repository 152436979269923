import * as React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { API, Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { addProject } from '../../../../state/project/projectSlice';
import { PrimaryButton } from '../../../buttons';
import 'react-toastify/dist/ReactToastify.css';

interface ProjectInput {
  name: string;
  description: string;
}

const CreateProjectForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { currentAccount } = useSelector((state: any) => state.account);
  const { projects } = useSelector((state: any) => state.projects);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const checkDuplicate = (arr: Array<Object>, name: string) => {
    return arr.some((e) => e.name === name);
  };

  const onSubmit = async (data: ProjectInput) => {
    setLoading(true);
    const dup = checkDuplicate(projects, data.name);
    if (dup) {
      toast.error('A Project with this name already exists');
      setLoading(false);
      return;
    }
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          name: data.name,
          description: data.name,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `accounts/${currentAccount.accountId}/projects`,
        myInit
      );
      dispatch(
        addProject({
          projectId: res.projectId,
          name: data.name,
          description: data.name,
        })
      );
      toast.success(`Your project has been created`);
      navigate(`/app/project/view/${res.projectId}`);
      setLoading(false);
    } catch (error) {
      toast.error(`We could not create your project, ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <Flex direction="column" maxW="370px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="name" isRequired>
          <FormLabel>Project Name</FormLabel>
          <Input
            height="50px"
            {...register('name', {
              required: true,
            })}
            type="text"
            placeholder="Project Name"
          />
          <FormHelperText color="red.500">
            {errors.name && <span>This field is required</span>}
          </FormHelperText>
        </FormControl>
        <PrimaryButton
          marginX="0px"
          paddingX="40px"
          paddingY="25px"
          isLoading={loading}
          type="submit"
        >
          Create Project
        </PrimaryButton>
      </form>
      <ToastContainer theme="colored" />
    </Flex>
  );
};

export default CreateProjectForm;
