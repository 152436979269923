import { Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';

interface SubSectionProps {
  children: JSX.Element | JSX.Element[];
  style?: FlexProps;
}

const SubSection = ({ children, style }: SubSectionProps) => {
  return (
    <Flex
      marginX="10px"
      w="100%"
      maxW="900px"
      padding="20px"
      direction="column"
      {...style}
    >
      {children}
    </Flex>
  );
};

export default SubSection;
