import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import Section from '../../layout/sections/subSection';
// import { MainSection } from '../../layout/sections';
import CreateProjectForm from '../../forms/project/createProject/create';
// import BasicInfoSection from "./sections/basicInfoSection/basicInfoSection";

const CreateProject = (): JSX.Element => {
  return (
    <Flex
      direction="column"
      bgColor="#fff"
      height="100%"
      marginX="40px"
      w="70vw"
      padding="20px"
      minH="40vh"
    >
      <Section>
        <Heading fontSize="26pt" marginBottom="20px">
          Create a New Project
        </Heading>
        <Box>
          <Text marginY="20px" marginLeft="20px">
            Give your Project a name
          </Text>
          <Box marginY="20px" marginLeft="20px">
            <CreateProjectForm />
          </Box>
        </Box>
      </Section>
    </Flex>
  );
};

export default CreateProject;
